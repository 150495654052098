<template>
  <base-section
    id="training-column"
    class="pb-5 pt-0"
  >
    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="4"
        >
          <base-info-card
            v-bind="card"
            align="center"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionTrainingColumn',

    data: () => ({
      classConf: '',
      cards: [
        {
          title: 'Purpose',
          subtitle: '',
          text: 'To impart knowledge, skills, and practical experience to individuals in the robotics field.',
          callout: '',
          icon: 'mdi-robot-industrial-outline',
        },
        {
          title: 'Significance',
          subtitle: '',
          text: 'To nurture industry-relevant talent that is aligned with the requirements of the job market of today.',
          callout: '',
          icon: 'mdi-account-hard-hat-outline',
        },
        {
          title: 'Goals',
          subtitle: '',
          text: 'To increase skilled workers throughout various industries that will soon rely on automation and robotics.',
          callout: '',
          icon: 'mdi-adjust',
        },
      ],
    }),
  }
</script>
